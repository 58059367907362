import axios from 'axios';
import { AppError } from '@/common/interfaces';

type Dictionary<T> = { [key: string]: T };

export default class Utils {
  /**
   * 連続した数字配列を作成
   */
  public static createNumberArray(length: number, init: number = 1): string[] {
    return [...Array(length).keys()].map(i => String(init + i));
  }
  /**
   * 連続した数字配列(1桁の場合頭に0をつける）を作成
   */
  public static createNumberArrayWith0(
    length: number,
    init: number = 1
  ): string[] {
    return [...Array(length).keys()].map(i =>
      String(('0' + (init + i)).slice(-2))
    );
  }

  /**
   * 2つの配列に同じ要素が含まれていれば TRUE を返却する
   */
  public static hasSameElement(A: any[], B: any[]): boolean {
    for (let i = 0; i < A.length; i++) {
      if (B.includes(A[i])) {
        return true;
      }
    }
    return false;
  }

  /**
   * 指定された年月から月末日を取得
   */
  public static getLastDayOfMonth(year: number, month: number): number {
    const date: any = new Date(year, month, 0);
    return date.getDate();
  }

  /**
   * いずれか必須チェック
   * 対象のうち、いずれか一つが入力されていることを検証
   * TODO (h.koyanagi.vc@future.co.jp) いずれか必須チェックをVeeValidateに組み込む（優先度低）
   */
  public static validateRequiredAny(values: string[]): boolean {
    return this.containsNotEmptyValue(values);
  }

  /**
   * 全て必須チェック
   * 対象が全て入力されていることを検証
   * TODO (h.koyanagi.vc@future.co.jp) 全て必須チェックをVeeValidateに組み込む（優先度低）
   */
  public static validateRequiredAll(values: string[]): boolean {
    return values.every((v: string) => !!v);
  }

  /**
   * 対象のうち、少なくとも一つは値を保持していること（空文字やnullではない値が存在すること）
   */
  public static containsNotEmptyValue(values: string[]): boolean {
    return values.some((v: string) => !!v);
  }

  /**
   * 合計桁数チェック
   * TODO (h.koyanagi.vc@future.co.jp) 合計桁数チェックをVeeValidateに組み込む（優先度低）
   */
  public static validateSumDigits(
    values: string[],
    min: number,
    max: number
  ): boolean {
    const length = values.join('').length;
    return length >= min && length <= max;
  }

  /**
   * いずれか必須チェックのエラーメッセージを取得
   */
  public static getRequiredAnyMsg(labels: string[]): string {
    // TODO (h.koyanagi.vc@future.co.jp) いずれか必須チェックのエラーメッセージを定数ファイルに定義（優先度低）
    // バリデーション用のメッセージファイルとメッセージ定数ファイルのどちらに定義するか確認
    return labels.join('と') + 'はいずれか必須項目です';
  }

  /**
   * 全て必須チェックのエラーメッセージを取得
   */
  public static allInputRequiredMsg(label: string): string {
    // バリデーション用のメッセージファイルとメッセージ定数ファイルのどちらに定義するか確認
    return `${label}は全ての欄を埋めてください`;
  }

  /**
   * 全て必須チェックのエラーメッセージを取得
   */
  public static getRequiredAllMsg(labels: string[]): string {
    // TODO (h.koyanagi.vc@future.co.jp) 全て必須チェックのエラーメッセージを定数ファイルに定義（優先度低）
    // バリデーション用のメッセージファイルとメッセージ定数ファイルのどちらに定義するか確認
    return labels.join('と') + 'は全ての欄を埋めてください';
  }

  /**
   * 合計桁数チェックのエラーメッセージを取得
   */
  public static getSumDigitsMsg(labels: string[]): string {
    // TODO (h.koyanagi.vc@future.co.jp) 合計桁数チェックのエラーメッセージを定数ファイルに定義（優先度低）
    // バリデーション用のメッセージファイルとメッセージ定数ファイルのどちらに定義するか確認
    return labels.join('と') + 'は10～11桁でご入力ください';
  }

  /**
   * 郵便番号APIの呼び出し
   * https://github.com/madefor/postal-code-api
   */
  public static fetchPostalCodeApi(
    zipcode1: string,
    zipcode2: string
  ): Promise<any> {
    const baseUrl = 'https://madefor.github.io/postal-code-api/api/v1/';
    const url = baseUrl + zipcode1 + '/' + zipcode2 + '.json';
    return axios.get(url).then((res: any) => res.data.data[0]);
  }

  /**
   * ひらがなをカタカナに変換
   */
  public static convertHiraToKana(str: string): any {
    return str.replace(/[\u3041-\u3096]/g, match => {
      const chr = match.charCodeAt(0) + 0x60;
      return String.fromCharCode(chr);
    });
  }

  /**
   * クッキーの取得
   */
  public static readCookie(): Map<string, string> {
    if (!document.cookie) {
      return new Map<string, string>();
    }
    const cookies = document.cookie.replace(/\s+/g, '').split(';');

    let map = new Map<string, string>();

    cookies.forEach((s: string) => {
      const kv = s.split('=');
      map.set(kv[0], kv[1]);
    });
    return map;
  }

  /**
   *  クッキーの削除
   */
  public static deleteCookie(key: string) {
    document.cookie = `${key}=; max-age=0`;
  }

  /**
   * 電ガス 契約番号フォーマット
   */
  public static formatContractNo(contract_no: string): string {
    const ContractNo1 = contract_no.slice(0, 4);
    const ContractNo2 = contract_no.slice(4, 6);
    const ContractNo3 = contract_no.slice(6);
    return `${ContractNo1}-${ContractNo2}-${ContractNo3}`;
  }

  public static isAppError(payload: any): payload is AppError {
    try {
      return (
        typeof payload.response.data.code === 'number' &&
        typeof payload.response.data.message === 'string'
      );
    } catch {
      return false;
    }
  }

  /**
   *  クエリから指定したクエリパラメータの値を文字列で取得
   *  値が配列の場合は空文字を返す
   */
  public static getSingleQueryString(
    query: Dictionary<string | (string | null)[]>,
    key: string,
    parser: (array: (string | null)[]) => string = (
      _array: (string | null)[]
    ) => {
      return '';
    }
  ): string {
    if (query[key]) {
      return Array.isArray(query[key])
        ? parser(query[key] as (string | null)[])
        : (query[key] as string);
    }
    return '';
  }
}
