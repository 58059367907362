

















import { Component, Vue } from 'vue-property-decorator';
import { PopupItem } from '@/models/PopupItem';
import { PopupRepository } from '@/repositories/PopupRepository';

@Component
export default class Popup extends Vue {
  popupArray: PopupItem[] = [];
  currentPopup: PopupItem | undefined = undefined;
  isOpen: boolean = false;

  // idTokenのポップアップID取得用key
  readonly POPUP_IDS_CLAIM_KEY = 'https://one-odakyu.com/popup';
  // microCMS通信のリトライ回数
  readonly RETRY_COUNT = 3;

  created() {
    this.onLoad();
  }

  onLoad() {
    // IDトークンにポップアップのキーが格納されていればmicroCMSにリクエストして表示する
    const popupIds = this.$auth.user[this.POPUP_IDS_CLAIM_KEY];
    if (!popupIds || popupIds.length === 0) {
      return;
    }
    this.fetchPopups(popupIds.join());
  }

  get repository() {
    return new PopupRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  async fetchPopups(ids: string) {
    const cmsRes = await this.repository.getItemsWithRetry(
      ids,
      this.RETRY_COUNT
    );
    if (cmsRes.totalCount === 0) {
      return;
    }
    this.popupArray = cmsRes.items;
    // microCMSに表示されている順に表示する
    this.currentPopup = this.popupArray.shift();
    this.isOpen = true;
  }

  async closeDialog() {
    this.isOpen = false;
    await new Promise(res => setTimeout(res, 500));
    this.currentPopup = this.popupArray.shift();
    if (this.currentPopup) {
      this.isOpen = true;
    }
  }
}
