import { IPopupItemRepository } from '@/repositories/interface/IPopupItemRepository';
import { PopupItemList } from '@/models/PopupItemList';
import { PopupResponse } from '@/models/cms/popup';
import { fetchListContents } from '@/common/cms';

export class PopupRepository implements IPopupItemRepository {
  private readonly PATH = 'popup';

  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * microCMS から複数のポップアップお知らせを取得する。
   * 情報の取得に失敗した場合、指定した回数分リトライし最後のリトライで失敗した場合は空の Promise<PopupItemResponse> を返す。
   * @param {string=} ids - 取得対象のid
   * @returns {Promise<PopupItemList>}
   */
  public async getItemsWithRetry(
    ids: string,
    retryCount: number
  ): Promise<PopupItemList> {
    for (let i = 0; i < retryCount; i++) {
      try {
        const resp = await fetchListContents<PopupResponse>(this.PATH, {
          ids
        });
        return PopupItemList.valueOf(
          resp.data.contents,
          resp.data.totalCount,
          this.isSP,
          this.supportsWebP
        );
      } catch (_) {
        continue;
      }
    }
    return PopupItemList.valueOf([], 0, this.isSP, this.supportsWebP);
  }
}
