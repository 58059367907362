import Vue from 'vue';
import Router, { Route, Location } from 'vue-router';
import {
  authGuard,
  authGuardTop,
  opGiftGuard,
  subscriptionGuard,
  signupGuard,
  emailVerifyGuard,
  errorCheckGuard,
  adultPasmoIDiGuard
} from '@/common/auth';
import $ from 'jquery';

import P0001 from '@/pages/P0001.vue';
import metatags from '@/resources/meta-tags.json';

type Next = (
  to?: string | false | void | Location | ((vm: Vue) => any) | undefined
) => void;

const DEFAULT_TITLE = '小田急ONE（オーネ）';
const DEFAULT_METATAGS = [
  {
    name: 'description',
    content:
      '小田急ONE（オーネ）は、1つのIDで「暮らし」「モビリティ」「スポーツ」「観光」などから多彩なサービスを安心してご利用いただけます。小田急グループはもちろん、提携しているシェアリングサービスもご紹介する暮らしに役立つサイトです。'
  }
];

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    //metatagsを設定していないファイル
    {
      path: '/error',
      name: 'error',
      component: () =>
        import(/* webpackChunkName: "error" */ '@/pages/error.vue')
    },
    {
      path: '/index.html',
      redirect: '/'
    },
    {
      path: '/my-page',
      redirect: to => {
        // リニューアル前のマイページ内タブへのリンクが存在するため、リニューアル後の該当ページへのリダイレクト対応を行う。
        switch (to.query.tab) {
          case 'top':
          case undefined:
            return { name: 'home', query: {} };
          case 'point-history':
            return { name: 'odakyu-point', query: { tab: 'point-history' } };
          case 'energy':
            return { name: 'energy', query: {} };
          case 'coupon-list':
            return { name: 'coupon-list', query: {} };
        }
        return { name: 'NotFound', query: {} };
      }
    },
    {
      // トラノコのアプリからアクセスされ、新規登録画面に遷移させる
      path: '/signup-redirect',
      name: 'signup-redirect',
      component: () =>
        import(
          /* webpackChunkName: "SignupRedirect" */ '@/pages/auth/SignupRedirect.vue'
        )
    },
    {
      path: '/forcelogin',
      name: 'force-login',
      props: route => ({ to: route.query.to || '' }),
      component: () =>
        import(
          /* webpackChunkName: "force-login" */ '@/components/energy/ForceLogin.vue'
        )
    },
    //metatagsを設定しているファイル（metatagsの昇順）
    {
      path: '/',
      name: 'home',
      // トップページはDynamicImportをしない。
      component: P0001,
      beforeEnter: signupGuard,
      meta: metatags.P0001
    },
    {
      path: '/not-available',
      name: 'not-available',
      component: () =>
        import(/* webpackChunkName: "sign-up" */ '@/pages/P0149.vue'),
      meta: metatags.P0001
    },
    {
      // OPWEB移行ユーザ用仮登録画面へのリダイレクト用画面
      path: '/signup-redirect-opweb',
      name: 'signup-redirect-opweb',
      component: () =>
        import(
          /* webpackChunkName: "SignupRedirectOpweb" */ '@/pages/auth/SignupRedirectOpweb.vue'
        )
    },
    {
      path: '/energy',
      name: 'energy',
      component: () =>
        import(/* webpackChunkName: "energy" */ '@/pages/P0019.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0019
    },
    {
      path: '/update-password',
      name: 'update-password',
      component: () =>
        import(/* webpackChunkName: "update-password" */ '@/pages/P0005.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0005
    },
    {
      path: '/faq',
      name: 'faq',
      component: () =>
        import(/* webpackChunkName: "faq" */ '@/pages/P0009.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0009
    },
    {
      path: '/profile',
      name: 'profile',
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/pages/P0016.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0016
    },
    {
      path: '/unsubscribe',
      name: 'unsubscribe',
      component: () =>
        import(/* webpackChunkName: "unsubscribe" */ '@/pages/P0017.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0017
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () =>
        import(/* webpackChunkName: "reset-password" */ '@/pages/P0021.vue'),
      meta: metatags.P0021
    },
    {
      path: '/service-list',
      name: 'service-list',
      component: () =>
        import(/* webpackChunkName: "service-list" */ '@/pages/P0027.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0027
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () =>
        import(/* webpackChunkName: "sign-up" */ '@/pages/P0102.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0102
    },
    {
      path: '/sign-up-complete',
      name: 'sign-up-complete',
      component: () =>
        import(/* webpackChunkName: "sign-up" */ '@/pages/P0104.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0104
    },
    {
      path: '/account-info',
      name: 'account-info',
      component: () =>
        import(/* webpackChunkName: "account-info" */ '@/pages/P0107.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0107
    },
    {
      path: '/update-email',
      name: 'update-email',
      component: () =>
        import(/* webpackChunkName: "update-email" */ '@/pages/P0108.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0108
    },
    {
      path: '/profile-edit',
      name: 'profile-edit',
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/pages/P0109.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0109
    },
    {
      path: '/contact-form',
      name: 'contact-form',
      component: () =>
        import(/* webpackChunkName: "contact-form" */ '@/pages/P0112.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0112
    },
    {
      path: '/contact-form-complete',
      name: 'contact-form-complete',
      component: () =>
        import(/* webpackChunkName: "contact-form" */ '@/pages/P0113.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0113
    },
    {
      path: '/update-password-ok',
      name: 'update-password-ok',
      component: () =>
        import(/* webpackChunkName: "update-password" */ '@/pages/P0115.vue'),
      beforeEnter: errorCheckGuard,
      meta: metatags.P0115
    },
    {
      path: '/update-email-ok',
      name: 'update-email-ok',
      component: () =>
        import(/* webpackChunkName: "update-email" */ '@/pages/P0116.vue'),
      meta: metatags.P0116
    },
    {
      path: '/unsubscribe-ok',
      name: 'unsubscribe-ok',
      component: () =>
        import(/* webpackChunkName: "unsubscribe" */ '@/pages/P0117.vue'),
      meta: metatags.P0117
    },
    {
      path: '/profile-complete',
      name: 'profile-complete',
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/pages/P0121.vue'),
      beforeEnter: authGuard,
      meta: metatags.P0121
    },
    {
      path: '/consent',
      name: 'consent',
      component: () =>
        import(/* webpackChunkName: "consent" */ '@/pages/P0146.vue'),
      meta: metatags.P0146
    },
    {
      path: '/campaign',
      name: 'campaign',
      component: () =>
        import(/* webpackChunkName: "campaign" */ '@/pages/P0147.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0147
    },
    {
      path: '/confirm',
      name: 'confirm',
      component: () =>
        import(/* webpackChunkName: "confirm" */ '@/pages/P0150.vue'),
      meta: metatags.P0150
    },
    {
      path: '/register-op-gift',
      name: 'register-op-gift',
      component: () =>
        import(/* webpackChunkName: "register-op-gift" */ '@/pages/P0154.vue'),
      beforeEnter: opGiftGuard,
      meta: metatags.P0154
    },
    {
      path: '/coupon-list',
      name: 'coupon-list',
      component: () =>
        import(/* webpackChunkName: "coupon-list" */ '@/pages/P0196.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0196
    },
    {
      path: '/news-list',
      name: 'news-list',
      component: () =>
        import(/* webpackChunkName: "news-list" */ '@/pages/P0207.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0207
    },
    {
      path: '/odakyu-point',
      name: 'odakyu-point',
      component: () =>
        import(/* webpackChunkName: "odakyu-point" */ '@/pages/P0213.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0213
    },
    // サブスク管理系
    {
      path: '/account-info/paid-services',
      name: 'manage-subscription',
      component: () =>
        import(
          /* webpackChunkName: "manage-subscription" */ '@/pages/P0222.vue'
        ),
      beforeEnter: authGuard,
      props: true,
      meta: metatags.P0222
    },
    {
      path: '/account-info/paid-services/:id/subscribe',
      name: 'register-subscription',
      component: () =>
        import(
          /* webpackChunkName: "payment-subscription" */ '@/pages/P0220.vue'
        ),
      beforeEnter: subscriptionGuard,
      props: true,
      meta: metatags.P0220
    },
    {
      path: '/account-info/paid-services/:id/unsubscribe',
      name: 'subscription-unsubscribe',
      component: () =>
        import(
          /* webpackChunkName: "subscription-unsubscribe" */ '@/pages/P0225.vue'
        ),
      beforeEnter: authGuard,
      meta: metatags.P0225
    },
    {
      path: '/account-info/paid-services/change-creditcard',
      name: 'change-subscription-creditcard',
      component: () =>
        import(
          /* webpackChunkName: "change-credit-card" */ '@/pages/P0227.vue'
        ),
      beforeEnter: authGuardTop,
      props: true,
      meta: metatags.P0227
    },
    {
      path: '/account-info/paid-services/change-creditcard-complete',
      name: 'change-subscription-creditcard-complete',
      component: () =>
        import(
          /* webpackChunkName: "change-credit-card-complete" */ '@/pages/P0242.vue'
        ),
      beforeEnter: authGuard,
      meta: metatags.P0242
    },
    {
      path: '/paid-services',
      name: 'paid-services-list',
      component: () =>
        import(
          /* webpackChunkName: "paid-services-list" */ '@/pages/P0197.vue'
        ),
      beforeEnter: signupGuard,
      meta: metatags.P0197
    },

    // 個別のLPとパスが重複しているが、ルートマッチは上にあるものから優先して判定されるため、パスでアクセスした場合（外部アクセス含む）はこちらが評価される。
    // リダイレクトはname指定で行っているため、リダイレクトループは発生し得ない。
    // 挙動に問題はないが、個別のLPはこのルーティングよりも下に定義すること。
    {
      path: '/paid-services/:id',
      name: 'subscription-lp',
      redirect: to => {
        return to.params.id
          ? { name: `subscription-lp-${to.params.id}` }
          : { name: 'manage-subscription' };
      }
    },
    // サブスク限定クーポン系
    {
      path: '/limited-coupon',
      name: 'limited-coupon',
      redirect: to => {
        // /limited-coupon?limited-coupon-id=xxx を /premium-coupons/online?coupon-id=xxx にリダイレクトする
        const location: Location = { name: 'premium-coupons' };
        const qKeyOld = 'limited-coupon-id';
        const qKeyNew = 'coupon-id';
        if (qKeyOld in to.query) {
          const val = to.query[qKeyOld];
          return {
            ...location,
            params: { tab: 'online' },
            query: { ...to.query, [qKeyNew]: val }
          };
        }
        return location;
      }
    },
    {
      path: '/premium-coupons/how-to-use-personalized-coupons',
      name: 'how-to-use-personalized-coupons',
      component: () =>
        import(
          /* webpackChunkName: "how-to-use-personalized-coupons" */ '@/pages/P0919.vue'
        ),
      meta: metatags.P0919
    },
    {
      path: '/premium-coupons/:tab?',
      name: 'premium-coupons',
      component: () =>
        import(/* webpackChunkName: "limited-coupon" */ '@/pages/P0231.vue'),
      meta: metatags.P0231
    },
    {
      path: '/events',
      name: 'events',
      component: () =>
        import(/* webpackChunkName: "event" */ '@/pages/P0232.vue'),
      meta: metatags.P0232
    },
    {
      path: '/events/:id',
      name: 'event-detail',
      component: () =>
        import(/* webpackChunkName: "events-detail" */ '@/pages/P0233.vue'),
      props: true,
      meta: metatags.P0233
    },
    {
      path: '/register-event',
      name: 'register-event',
      component: () =>
        import(/* webpackChunkName: "register-event" */ '@/pages/P0235.vue'),
      beforeEnter: authGuardTop,
      props: true,
      meta: metatags.P0235
    },
    {
      path: '/register-event-complete',
      name: 'register-event-complete',
      component: () =>
        import(
          /* webpackChunkName: "register-event-complete" */ '@/pages/P0243.vue'
        ),
      beforeEnter: authGuard,
      props: true,
      meta: metatags.P0243
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: () =>
        import(/* webpackChunkName: "verify-email" */ '@/pages/P0269.vue'),
      props: true,
      meta: metatags.P0269
    },
    {
      path: '/service/sharedine',
      name: 'sharedine',
      component: () =>
        import(/* webpackChunkName: "sharedine" */ '@/pages/P0701.vue'),
      meta: metatags.P0701
    },
    {
      path: '/service/skuroo',
      name: 'skuroo',
      component: () =>
        import(/* webpackChunkName: "skuroo" */ '@/pages/P0702.vue'),
      meta: metatags.P0702
    },
    {
      path: '/service/ikasa',
      name: 'ikasa',
      component: () =>
        import(/* webpackChunkName: "ikasa" */ '@/pages/P0703.vue'),
      meta: metatags.P0703
    },
    {
      path: '/service/kidsnasitter',
      name: 'kidsnasitter',
      component: () =>
        import(/* webpackChunkName: "kidsnasitter" */ '@/pages/P0704.vue'),
      meta: metatags.P0704
    },
    {
      path: '/service/otetsutabi',
      name: 'otetsutabi',
      component: () =>
        import(/* webpackChunkName: "otetsutabi" */ '@/pages/P0707.vue'),
      meta: metatags.P0707
    },
    {
      path: '/service/odakyu-energy',
      name: 'odakyu-energy',
      component: () =>
        import(/* webpackChunkName: "odakyu-energy" */ '@/pages/P0708.vue'),
      meta: metatags.P0708
    },
    {
      path: '/service/toranoko',
      name: 'toranoko',
      component: () =>
        import(/* webpackChunkName: "toranoko" */ '@/pages/P0709.vue'),
      meta: metatags.P0709
    },
    {
      path: '/service/chargespot',
      name: 'chargespot',
      component: () =>
        import(/* webpackChunkName: "chargespot" */ '@/pages/P0710.vue'),
      meta: metatags.P0710
    },
    {
      path: '/service/fiika',
      name: 'fiika',
      component: () =>
        import(/* webpackChunkName: "fiika" */ '@/pages/P0712.vue'),
      meta: metatags.P0712
    },
    {
      path: '/service/creal',
      name: 'creal',
      component: () =>
        import(/* webpackChunkName: "creal" */ '@/pages/P0713.vue'),
      meta: metatags.P0713
    },
    {
      path: '/service/kurashisupport',
      name: 'kurashisupport',
      component: () =>
        import(/* webpackChunkName: "kurashisupport" */ '@/pages/P0714.vue'),
      meta: metatags.P0714
    },
    {
      path: '/service/odakyu-travel',
      name: 'odakyu-travel',
      component: () =>
        import(/* webpackChunkName: "odakyu-travel" */ '@/pages/P0715.vue'),
      meta: metatags.P0715
    },
    {
      path: '/tos',
      name: 'tos',
      component: () =>
        import(/* webpackChunkName: "tos" */ '@/pages/P0901.vue'),
      meta: metatags.P0901
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () =>
        import(/* webpackChunkName: "privacy" */ '@/pages/P0902.vue'),
      meta: metatags.P0902
    },
    {
      path: '/sitepolicy',
      name: 'sitepolicy',
      component: () =>
        import(/* webpackChunkName: "sitepolicy" */ '@/pages/P0903.vue'),
      meta: metatags.P0903
    },
    {
      path: '/cookie',
      name: 'cookie',
      redirect: { name: 'privacy' },
      meta: metatags.P0904
    },
    {
      path: '/whats',
      name: 'whats',
      component: () =>
        import(/* webpackChunkName: "whats" */ '@/pages/P0905.vue'),
      meta: metatags.P0905
    },
    {
      path: '/news/:id',
      name: 'news',
      component: () =>
        import(/* webpackChunkName: "news" */ '@/pages/P0906.vue'),
      props: true,
      beforeEnter: signupGuard,
      meta: metatags.P0906
    },
    {
      path: '/odekake-point',
      name: 'odekake-point',
      component: () =>
        import(/* webpackChunkName: "odekake-point" */ '@/pages/P0175.vue'),
      props: true,
      beforeEnter: adultPasmoIDiGuard,
      meta: metatags.P0175
    },
    {
      path: '/campaign-list',
      name: 'campaign-list',
      component: () =>
        import(/* webpackChunkName: "campaign-list" */ '@/pages/P0195.vue'),
      beforeEnter: signupGuard,
      meta: metatags.P0195
    },
    {
      path: '/opweb-switch',
      name: 'opweb-switch',
      component: () =>
        import(/* webpackChunkName: "opweb-switch" */ '@/pages/P0209.vue'),
      meta: metatags.P0209
    },
    {
      path: '/opcard-welcome',
      name: 'opcard-welcome',
      component: () =>
        import(/* webpackChunkName: "opcard-welcome" */ '@/pages/P0209.vue'),
      meta: metatags.P0209OpCard
    },
    {
      path: '/pasmo-charge',
      name: 'pasmo-charge',
      component: () =>
        import(/* webpackChunkName: "pasmo-charge" */ '@/pages/P0908.vue'),
      meta: metatags.P0908
    },
    {
      path: '/passport-family-package',
      name: 'subscription-lp-family-package',
      component: () =>
        import(
          /* webpackChunkName: "passport-family-package" */ '@/pages/P0909.vue'
        ),
      meta: metatags.P0909
    },
    {
      path: '/passport-family-package/mimamori',
      name: 'mimamori',
      component: () =>
        import(/* webpackChunkName: "mimamori" */ '@/pages/P0910.vue'),
      meta: metatags.P0910
    },
    {
      path: '/op-gift',
      name: 'op-gift',
      component: () =>
        import(/* webpackChunkName: "op-gift" */ '@/pages/P0911.vue'),
      meta: metatags.P0911
    },
    {
      path: '/vision',
      name: 'vision',
      component: () =>
        import(/* webpackChunkName: "vision" */ '@/pages/P0912.vue'),
      meta: metatags.P0912
    },
    {
      path: '/mobile-pasmo-charge',
      name: 'mobile-pasmo-charge',
      component: () =>
        import(
          /* webpackChunkName: "mobile-pasmo-charge" */ '@/pages/P0215.vue'
        ),
      beforeEnter: authGuard,
      props: true,
      meta: metatags.P0215
    },
    {
      path: '/article/:id',
      name: 'article-detail',
      component: () =>
        import(/* webpackChunkName: "article-detail" */ '@/pages/P0913.vue'),
      props: true,
      meta: metatags.P0913
    },
    {
      path: '/article',
      name: 'article',
      component: () =>
        import(/* webpackChunkName: "article" */ '@/pages/P0914.vue'),
      props: true,
      meta: metatags.P0914
    },
    {
      path: '/campaign2023/odepo-arigatou1',
      name: 'odepo-arigatou1',
      component: () =>
        import(/* webpackChunkName: "odepo-arigatou1" */ '@/pages/P0915.vue'),
      props: true,
      meta: metatags.P0915
    },
    {
      path: '/odakyu-point/exchange-items',
      name: 'odakyu-point-exchange-items',
      component: () =>
        import(
          /* webpackChunkName: "odakyu-point-exchange-items" */ '@/pages/P0246.vue'
        ),
      props: true,
      meta: metatags.P0246
    },
    {
      path: '/odakyu-point/exchange-items/:id',
      name: 'odakyu-point-exchange-item-detail',
      component: () =>
        import(
          /* webpackChunkName: "odakyu-point-exchange-item-detail" */ '@/pages/P0247.vue'
        ),
      props: true,
      meta: metatags.P0247
    },
    {
      path: '/register-op-card',
      name: 'register-op-card',
      redirect: () => {
        // OPアプリからリニューアル前のOPカード紐付けへのリンクが存在するため、リニューアル後の該当ページへのリダイレクト対応を行う。
        return { name: 'odakyu-point', query: { tab: 'op-card' } };
      }
    },
    {
      path: '/campaign2023/teiki-touroku',
      name: 'teiki-touroku',
      component: () =>
        import(/* webpackChunkName: "teiki-touroku" */ '@/pages/P0916.vue'),
      meta: metatags.P0916
    },
    {
      path: '/campaign2024/odepogogo',
      name: 'odepogogo',
      component: () =>
        import(/* webpackChunkName: "odepogogo" */ '@/pages/P0917.vue'),
      props: true,
      meta: metatags.P0917
    },
    {
      path: '/paid-services/ebina-passport',
      name: 'subscription-lp-ebina-passport',
      component: () =>
        import(
          /* webpackChunkName: "subscription-lp-ebina-passport" */ '@/pages/P0918.vue'
        ),
      props: true,
      meta: metatags.P0918
    },
    {
      path: '/campaign2024/odepobus-train',
      name: 'odepobus-train',
      component: () =>
        import(/* webpackChunkName: "odepobus-train" */ '@/pages/P0920.vue'),
      props: true,
      meta: metatags.P0920
    },
    // NotFoundPageはこの位置(一番最後)から移動させないでください。
    {
      path: '*',
      name: 'NotFound',
      component: () =>
        import(/* webpackChunkName: "NotFound" */ '@/pages/notfound.vue')
    }
  ],
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

/**
 * VisibleForTesting
 * 各ページごとにCSS classをアタッチする
 * @param to
 * @param from
 */
export const changeHtmlClass = (to: Route, from: Route) => {
  const preClass = 'page__' + from.name;
  const htmlClass = 'page__' + to.name;
  // TODO(k.sakiyama.d7@future.co.jp) 脱jquery
  $('html')
    .removeClass(preClass)
    .addClass(htmlClass);
  $('body').removeClass('none_scrollbody');
};

/**
 * VisibleForTesting
 * @param to
 */
export const changeHeadTitle = (to: Route) => {
  document.title = to.meta.title || DEFAULT_TITLE;
};

/**
 * VisibleForTesting
 * meta-tags.jsonで定義された内容を反映する
 * @param to
 * @param from
 * @param next
 */
export const changeHeadMetatags = (to: Route, next: Next) => {
  const controlled_attr = 'data-vue-router-controlled';

  // 遷移前のページに設定してあったmetatagを削除
  Array.from(document.querySelectorAll(`[${controlled_attr}]`)).map(el =>
    // @ts-ignore
    el.parentNode.removeChild(el)
  );

  // 新しいmetatagをtoから取得する
  const to_metatags = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // toに設定されてなければデフォルト
  const new_metatags = to_metatags || { meta: { metaTags: DEFAULT_METATAGS } };

  // 新しいタグをつける
  (new_metatags as any).meta.metaTags
    .map((tagDef: { [x: string]: string }) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute(controlled_attr, '');

      return tag;
    })
    .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));

  return next();
};

/**
 * VisibleForTesting
 * 別ページへ遷移する前に実行される
 * @param to
 * @param from
 * @param next
 */
export const beforeEach = (to: Route, from: Route, next: Next) => {
  changeHtmlClass(to, from);
  changeHeadTitle(to);
  changeHeadMetatags(to, next);
};

router.beforeEach((to, from, next) => beforeEach(to, from, next));

router.beforeResolve((to, from, next) => emailVerifyGuard(to, from, next));

router.afterEach(() => {
  const usag = navigator.userAgent.toLowerCase(),
    is = function(t: any) {
      return usag.indexOf(t) > -1;
    },
    b = [
      !/opera|webtv/i.test(usag) && /msie\s(\d)/.test(usag)
        ? 'ie ie' + RegExp.$1
        : is('trident/7')
        ? 'ie-11'
        : is('trident')
        ? 'ie'
        : ''
    ];
  const browser = b.join(' ');
  if (browser.indexOf('ie-11') != -1 || browser.indexOf('ie') != -1) {
    alert(
      'ご使用中のブラウザ（InternetExplorer）は表示の崩れや動作が正常に行われない場合がございます。サイトポリシーに掲載されている推奨ブラウザでご利用ください。'
    );
  }
});

export default router;
