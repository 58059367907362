import message from '@/common/message';

export default {
  install(Vue: any) {
    Vue.prototype.$tokenErrHandler = (error: any) => {
      if (error && error.response) {
        switch (error.response.status) {
          case 401:
            location.href = `/?error=access_denied&error_description=${message.get(
              '9000001'
            )}`;
            return;
          case 500:
            location.href =
              '/?error=internal error&error_description=Sorry, something went wrong.';
            return;
        }
      }

      throw error;
    };
  }
};
