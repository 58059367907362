import { PopupResponse } from '@/models/cms/popup';

export class PopupItem {
  static valueOf(r: PopupResponse, isSP: boolean, supportsWebP: boolean) {
    return new PopupItem(
      isSP,
      supportsWebP,
      r.id,
      r.createdAt,
      r.updatedAt,
      r.publishedAt,
      r.revisedAt,
      r.title,
      r.contents
    );
  }

  private constructor(
    readonly isSP: boolean,
    readonly supportsWebP: boolean,
    readonly id: string,
    readonly createdAt: string,
    readonly updatedAt: string,
    readonly publishedAt: string,
    readonly revisedAt: string,
    readonly title: string,
    readonly contents: string
  ) {}
}
