import { PopupItem } from '@/models/PopupItem';
import { PopupResponse } from '@/models/cms/popup';

export class PopupItemList {
  static valueOf(
    responses: PopupResponse[],
    totalCount: number,
    isSP: boolean,
    supportsWebP: boolean
  ) {
    return new PopupItemList(
      responses.map(r => PopupItem.valueOf(r, isSP, supportsWebP)),
      totalCount
    );
  }

  private constructor(
    readonly items: PopupItem[],
    readonly totalCount: number
  ) {}
}
